import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];
  static values = {
    title: String,
    artist: String,
    album: String,
    artwork: String,
    mime: String,
    next: String,
    prev: String,
  };

  streaming (event = undefined) {
    if (!('mediaSession' in navigator)) return;

    navigator.mediaSession.metadata = new MediaMetadata({
      title: this.titleValue,
      artist: this.artistValue,
      artwork: [
        {
          src: this.artworkValue,
          sizes: "512x512",
          type: this.mimeValue
        },
      ],
    })

    this.setActionHandler("play", async () => {
      try {
        await this.sourceTarget.play();

        navigator.mediaSession.playbackState = "playing";
      } catch (e) {
        console.error(e);
      }
    });
    this.setActionHandler("pause", async () => {
      try {
        await this.sourceTarget.pause();

        navigator.mediaSession.playbackState = "paused";
      } catch (e) {
        console.error(e);
      }
    });

    this.setActionHandler("nexttrack", () => {
      window.dispatchEvent(new CustomEvent("playById", { detail: { id: this.nextValue } }));
    });

    this.setActionHandler("previoustrack", () => {
      window.dispatchEvent(new CustomEvent("playById", { detail: { id: this.prevValue } }));
    });
  }

  // XXX: _Because some media session actions may not be supported, it
  // is recommended to use a try…catch block when setting them._
  // https://web.dev/media-session/
  setActionHandler (handler, callback) {
    try {
      navigator.mediaSession.setActionHandler(handler, callback);
    } catch (e) {
      console.error(e);
    }
  }
}
