import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "audio",
    "current",
    "duration",
    "progress",
    "icon",
    "range",
  ];

  static values = {
    id: String,
    timeSet: {
      type: Boolean,
      default: false,
    },
  };

  updateTime(event) {
    if (this.timeSetValue) return;

    if (this.hasDurationTarget)
      this.durationTarget.innerHTML = this.timeFormat(
        this.audioTarget.duration
      );

    if (this.hasRangeTarget) {
      this.rangeTarget.max = this.audioTarget.duration;
      this.rangeTarget.style.setProperty("--max", this.audioTarget.duration);
    }

    this.timeSetValue = true;
  }

  changeCurrentTime() {
    this.audioTarget.currentTime = this.rangeTarget.value;
  }

  updateProgress() {
    const currentTime = Math.floor(this.audioTarget.currentTime);

    if (this.hasCurrentTarget)
      this.currentTarget.innerHTML = this.timeFormat(currentTime);

    if (this.hasRangeTarget) {
      this.rangeTarget.value = currentTime;
      this.rangeTarget.style.setProperty("--val", currentTime);
    }
  }

  // Basado en https://stackoverflow.com/a/6313008
  timeFormat(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds - h * 3600) / 60);
    const s = Math.floor(seconds - h * 3600 - m * 60);
    const p = [];

    if (h > 0) p.push(`${h < 10 ? "0" : ""}${h}:`);

    p.push(`${m < 10 ? "0" : ""}${m}:`);
    p.push(`${s < 10 ? "0" : ""}${s}`);

    return p.join("");
  }

  playing(event = undefined) {
    if (!this.hasIconTarget) return;

    this.iconTarget.classList.remove("fa-play");
    this.iconTarget.classList.add("fa-pause");

    window.dispatchEvent(new CustomEvent("pauseEverythingElse", { detail: { id: this.idValue } }));
  }

  pausing(event = undefined) {
    if (!this.hasIconTarget) return;

    this.iconTarget.classList.remove("fa-pause");
    this.iconTarget.classList.add("fa-play");
  }

  async toggle(event = undefined) {
    event?.preventDefault();

    const action = this.audioTarget.paused ? "play" : "pause";

    try {
      await this.audioTarget[action]();
    } catch (e) {
      console.error(e);
    }
  }

  async pause({ detail: { id } }) {
    if (this.idValue === id) return;

    try {
      await this.audioTarget.pause();
    } catch (e) {
      console.error(e);
    }
  }

  async play({ detail: { id } }) {
    if (this.idValue !== id) return;

    try {
      await this.audioTarget.play();
    } catch (e) {
      console.error(e);
    }
  }
}
